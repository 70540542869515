<template>
  <div>
    {{message}}
  </div>
</template>

<script>

  export default {
    name: "src-pages-body-auth-change_email-v1",
    data () {
      return {
        message: ''
      }
    },
    mounted() {
      if(this.getRouterQueryActivationCode) {
        this.api.user.changeEmail(this.getRouterQueryActivationCode, this.getRouterQueryUUID).then(({data}) => {
          let result = data;
          if(result.success) {
            this.$store.commit(this.types.SET_USER_CHANGES, {key: 'email', value: result.data.email});
          }
          this.$router.push(result.data.redirect_url);
        }).catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      }
    },
    computed:{

      getRouterQueryActivationCode() {
        return this.$store.getters.getRouterQueryActivationCode;
      },

      getRouterQueryUUID() {
        return this.$store.getters.getRouterQueryUUID;
      }
    }
  }
</script>

<style scoped>
  h1, h2 {
    font-weight: normal;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>
